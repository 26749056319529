<template>
  <div class="flex flex-col justify-center py-10 sm:px-6 lg:px-8 min-h-screen">
    <div
      class="loading init-loading-status flex items-center justify-center p-10"
    >
      <loading></loading>
    </div>
  </div>
</template>

<script>
import Loading from './loading.vue';
export default {
  components: {
    Loading,
  },
};
</script>
