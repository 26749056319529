<template>
  <div
    aria-live="assertive"
    id="notifications"
    class="fixed inset-0 flex items-start p-6 pointer-events-none z-50"
  >
    <div class="w-full flex flex-col items-end space-y-4">
      <div
        v-for="el in notifications"
        class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden animate-fade-scale-in"
        :key="el.id"
        :ref="`notification-${el.id}`"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <svg
                v-if="el.type === 'success'"
                class="h-6 w-6 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <svg
                v-if="el.type === 'fail'"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-red-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p v-if="el.title" class="text-sm font-medium text-gray-900">
                {{ el.title }}
              </p>
              <p v-if="el.message" class="mt-1 text-sm text-gray-500">
                {{ el.message }}
              </p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                v-on:click="removeNotification($event, el.id)"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue';
import UUIDv7 from '@root/uuidv7';
const { uuidv7: uuid } = UUIDv7;
import Notification from '../lib/notification.js';

export default {
  data: function () {
    return {
      notifications: [],
      timeouts: {},
    };
  },
  methods: {
    createNotification: function (params = {}) {
      const vm = this;

      params.id = params.id || uuid();
      params.type = params.type || 'success';
      params.timeout = params.timeout !== undefined ? params.timeout : 10000;

      let idList = vm.notifications.map((el) => el.id);

      if (!idList.includes(params.id)) {
        vm.notifications.push(params);
      }

      let timeoutIdList = Object.keys(vm.timeouts);

      if (params.timeout > 0 && !timeoutIdList.includes(params.id)) {
        vm.timeouts[params.id] = setTimeout(function () {
          vm.removeNotification(null, params.id);
        }, params.timeout);
      }
      if (params.timeout > 0 && timeoutIdList.includes(params.id)) {
        clearTimeout(vm.timeouts[params.id]);
        vm.timeouts[params.id] = setTimeout(function () {
          vm.removeNotification(null, params.id);
        }, params.timeout);
      }
      if (params.timeout === 0 && timeoutIdList.includes(params.id)) {
        clearTimeout(vm.timeouts[params.id]);
      }
    },
    removeNotification: function ($event, id) {
      const vm = this;
      if ($event) {
        $event.preventDefault();
      }

      Vue.nextTick(function () {
        const $el = vm.$refs[`notification-${id}`]?.[0];
        if ($el) {
          $el.classList.remove('animate-fade-scale-in');
          $el.classList.add('animate-fade-out');
          $el.classList.add('opacity-0');
        }

        setTimeout(function () {
          let index = vm.notifications.findIndex(function (notification) {
            return notification.id === id;
          });
          if (index !== -1) {
            vm.notifications.splice(index, 1);
          }
        }, 300);
      });
    },
  },
  mounted: function () {
    Notification.register(this);
  },
};
</script>
