<template>
  <div class="px-6">
    <div class="page-header mb-6 sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h2 class="text-xl font-medium text-gray-900">Weirdos Google</h2>
        <p class="mt-1 text-sm text-gray-500">
          A list of invalid Google Workspace accounts for debugging.
        </p>
      </div>
    </div>
    <div v-if="loading" class="mt-2 mb-6"><loading></loading></div>
    <div v-if="!loading" class="flow-root">
      <div class="-mx-6">
        <div class="inline-block min-w-full">
          <table class="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Object
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <template v-for="el in weirdos" :key="el.primaryEmail">
                <tr class="bg-white">
                  <td
                    class="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 border-b border-gray-200"
                  >
                    {{ el.primaryEmail }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-gray-900 border-b border-gray-200"
                  >
                    {{ $root.getPreferredName({ google: el }) }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-gray-900 border-b border-gray-200"
                  >
                    <button
                      @click="toggleCodeClass($event, el.primaryEmail)"
                      type="button"
                      class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Show Object
                    </button>
                    <pre
                      :class="codeClass[el.primaryEmail]"
                      class="max-w-xl mt-2 bg-gray-100 text-gray-800 p-4 rounded-lg overflow-x-auto text-sm"
                      >{{ JSON.stringify(el, null, 4) }}</pre
                    >
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import F from '../lib/fetch.js';
import Loading from './loading.vue';

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      weirdos: [],
      loading: true,
      codeClass: {},
    };
  },
  methods: {
    getWeirdos: async function () {
      let vm = this;
      let resp = await F.fetch('/api/admin/weirdos');
      let weirdos = await resp.json();
      vm.weirdos = weirdos.Google.weirdosByEmail;
      return vm.weirdos;
    },
    initCodeClass: function () {
      let vm = this;
      for (let wid of Object.keys(vm.weirdos)) {
        // console.log(wid);
        vm.codeClass[wid] = '!h-0 !p-0 !m-0 overflow-hidden';
      }
    },
    toggleCodeClass: function ($event, id) {
      let vm = this;
      if ($event) {
        $event.preventDefault();
      }
      let idList = Object.keys(vm.weirdos);
      for (let wid of idList) {
        if (wid === id) {
          if (vm.codeClass[id] === '') {
            vm.codeClass[wid] = '!h-0 !p-0 !m-0 overflow-hidden';
          } else {
            vm.codeClass[wid] = '';
          }
        }
      }
    },
  },
  mounted: async function () {
    let vm = this;
    vm.loading = true;
    await vm.getWeirdos();
    vm.initCodeClass();
    vm.loading = false;
  },
};
</script>
