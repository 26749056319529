'use strict';

import '../css/style.css';
import * as Vue from 'vue';
import LoadingPage from './components/loading-page.vue';
import AppHeader from './components/app-header.vue';
import Dashboard from './components/dashboard.vue';
import F from './lib/fetch.js';
import delay from './lib/delay.js';
import router from './router/index.js';
import Notifications from './components/notifications.vue';
import Notification from './lib/notification.js';

const app = Vue.createApp({
  data: function () {
    return {
      showLoading: false,
      showApp: false,
      me: null,
    };
  },
  components: {
    LoadingPage,
    AppHeader,
    Dashboard,
    Notifications,
  },
  methods: {
    getMe: async function () {
      let vm = this;
      let resp = await F.fetch('/api/employees/me');
      let me = await resp.json();
      vm.me = me;
    },
    toSignin: function () {
      localStorage.setItem('returnTo', '/');
      localStorage.setItem('userNeedsSignIn', true);
      window.location.href = '/signin';
    },
    signOut: async function ($event) {
      if ($event) {
        $event.preventDefault();
      }
      let resp = await fetch('/api/authn/session', { method: 'DELETE' });
      if (resp.ok) {
        window.location.href = '/signin';
      }
    },
    // hasRole: function (role) {
    hasRole: function (role) {
      return this.me.roles.includes(role);
    },
    getLegalName: function (emp) {
      // let vm = this;
      if (!emp.foundation) {
        return null;
      }
      return [
        emp.foundation.first_name,
        // emp.foundation.middle_initial,
        emp.foundation.last_name,
      ]
        .filter(Boolean)
        .join(' ');
    },
    getPreferredName: function (emp) {
      if (!emp.google) {
        return null;
      }
      if (emp.google) {
        return emp.google.name.fullName;
      }
      return null;
    },
    getPreferredEmail: function (emp) {
      if (emp.google) {
        return emp.google.primaryEmail;
      }
      if (emp.foundation) {
        return emp.foundation.email_address;
      }
      return null;
    },
    getEmpTitle: function (employee) {
      if (
        employee &&
        employee.google &&
        Array.isArray(employee.google.organizations) &&
        employee.google.organizations.length > 0 &&
        employee.google.organizations[0].title
      ) {
        return employee.google.organizations[0].title;
      }

      return null;
    },
    getPhoneNumber: function (employee) {
      if (
        !employee ||
        !employee.google ||
        !Array.isArray(employee.google.phones)
      ) {
        return null;
      }

      var phones = employee.google.phones;

      var mobilePhone = phones.find(function (phone) {
        return phone.type === 'mobile' && phone.value;
      });

      if (mobilePhone) {
        return mobilePhone.value;
      }

      var anyPhone = phones.find(function (phone) {
        return phone.value;
      });

      if (anyPhone) {
        return anyPhone.value;
      }

      return null;
    },
    formatPhoneNumber: function (phone) {
      if (!phone) {
        return null;
      }

      var digits = phone.replace(/\D/g, '');

      if (digits.length !== 10) {
        return null;
      }

      var areaCode = digits.substring(0, 3);
      var firstPart = digits.substring(3, 6);
      var secondPart = digits.substring(6, 10);

      return '(' + areaCode + ') ' + firstPart + '-' + secondPart;
    },
  },
  computed: {
    companyEmail: function () {
      let vm = this;

      if (!vm.me.google) {
        return null;
      }

      let emails = vm.me.google.emails;

      let primaryEmail = emails.find(function (el) {
        return el.primary;
      });

      return primaryEmail.address;
    },
    personalEmail: function () {
      let vm = this;

      if (!vm.me.foundation) {
        return null;
      }

      return vm.me.foundation.email_address;
    },
    managerEmail: function () {
      let vm = this;

      try {
        let manager = vm.me.google.relations.find(function (el) {
          return el.type === 'manager';
        });
        return manager.value;
      } catch (err) {
        return null;
      }
    },
  },
  created: async function () {
    let vm = this;

    vm.showLoading = true;

    let idToken = await F.getIdToken().catch(function (err) {
      if (err.code === 'E_SESSION_INVALID') {
        return '';
      }
      throw err;
    });
    if (!idToken) {
      await delay(500);
      vm.toSignin();
      return;
    }

    await vm.getMe();

    Object.assign(vm, {
      showLoading: false,
      showApp: true,
    });
  },
});

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideHandler = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        // console.log(binding);
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideHandler);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideHandler);
  },
});

app.use(router);

app.mount('#app');

// app.config.errorHandler = async function (err, vm, info) {
app.config.errorHandler = async function (err, vm) {
  console.dir(err);
  // Unhandled unauthorized request
  if (err.status === 401) {
    void (await vm.$root.signOut());
    return;
  }

  if (err.message) {
    Notification.create({
      type: err.type || 'fail',
      title: err.title || 'Undocumented Error',
      message: err.message,
    });
    return;
  }

  // TODO Send error to an external service

  // Alert that users should never see
  window.alert(
    'An unexpected error has occurred in the application. Please refresh and try again. If the error persists, contact support at app@cogburnbros.com for help.'
  );
  console.log(err);

  // void (await vm.$root.signOut());
};
