'use strict';

import { createRouter, createWebHistory } from 'vue-router';

// Import the components for the routes
import Dashboard from '../components/dashboard.vue';
import Profile from '../components/profile.vue';
import AdminEmployees from '../components/admin-employees.vue';
import AdminWeirdosFoundation from '../components/admin-weirdos-foundation.vue';
import AdminWeirdosGoogle from '../components/admin-weirdos-google.vue';

const routes = [
  { path: '/', name: 'Dashboard', component: Dashboard },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/admin/employees', name: 'Employees', component: AdminEmployees },
  {
    path: '/admin/weirdos/foundation',
    name: 'Weirdos Foundation',
    component: AdminWeirdosFoundation,
  },
  {
    path: '/admin/weirdos/google',
    name: 'Weirdos Google',
    component: AdminWeirdosGoogle,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(function (to, from, next) {
  next();
});

export default router;
