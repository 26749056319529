<template>
  <div
    v-if="loading"
    class="flex items-center justify-center mt-8 mb-12 md:mb-6"
  >
    <loading></loading>
  </div>
  <section
    v-else
    class="nav-section md:p-6 md:bg-white md:rounded-lg md:shadow-md animate-fade-move-in mb-12 md:mb-6"
  >
    <h2 class="text-xl font-medium text-red-800 mb-3">{{ title }}</h2>
    <div class="space-y-2">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped>
.nav-section :deep(a.link) {
  @apply block;
  @apply text-gray-600;
  @apply hover:text-red-800;
}
</style>

<script>
import Loading from './loading.vue';

export default {
  name: 'NavSection',
  components: {
    Loading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
