'use strict';

let Notification = {
  _component: null,
};

Notification.register = function (component) {
  Notification._component = component;
};

Notification.create = function (params = {}) {
  if (Notification._component) {
    Notification._component.createNotification(params);
  } else {
    console.warn('Notification component not registered.');
  }
};

export default Notification;
