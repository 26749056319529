<template>
  <div class="max-w-3xl mx-auto">
    <div class="w-full mx-auto p-6">
      <div class="md:bg-gray-50 text-gray-800">
        <div
          class="max-w-3xl mx-auto mb-6 md:px-6 md:pt-6 md:pb-2 md:bg-white md:rounded-lg md:shadow-md animate-fade-move-in"
        >
          <div>
            <h2 class="text-xl mb-3 font-medium">My Profile</h2>
            <p class="mt-1 max-w-2xl text-gray-500">
              This is your employee profile.
            </p>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div
                v-if="$root.getPreferredName($root.me)"
                class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm/6 font-medium text-gray-900">
                  Preferred Name
                </dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ $root.getPreferredName($root.me) }}
                </dd>
              </div>
              <div
                v-if="$root.getLegalName($root.me)"
                class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm/6 font-medium text-gray-900">Legal Name</dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ $root.getLegalName($root.me) }}
                </dd>
              </div>
              <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm/6 font-medium text-gray-900">
                  Company Email
                </dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ $root.companyEmail }}
                </dd>
              </div>
              <div class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm/6 font-medium text-gray-900">
                  Personal Email
                </dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ $root.personalEmail }}
                </dd>
              </div>
              <div
                v-if="$root.managerEmail"
                class="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm/6 font-medium text-gray-900">
                  Manager Email
                </dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ $root.managerEmail }}
                </dd>
              </div>
              <div
                v-if="false"
                class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm/6 font-medium text-gray-900">About</dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
                  incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
                  consequat sint. Sit id mollit nulla mollit nostrud in ea
                  officia proident. Irure nostrud pariatur mollit ad adipisicing
                  reprehenderit deserunt qui eu.
                </dd>
              </div>
              <div
                v-if="false"
                class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm/6 font-medium text-gray-900">Attachments</dt>
                <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul
                    role="list"
                    class="divide-y divide-gray-100 rounded-md border border-gray-200"
                  >
                    <li
                      class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6"
                    >
                      <div class="flex w-0 flex-1 items-center">
                        <svg
                          class="size-5 shrink-0 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium"
                            >resume_back_end_developer.pdf</span
                          >
                          <span class="shrink-0 text-gray-400">2.4mb</span>
                        </div>
                      </div>
                      <div class="ml-4 shrink-0">
                        <a
                          href="#"
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                          >Download</a
                        >
                      </div>
                    </li>
                    <li
                      class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6"
                    >
                      <div class="flex w-0 flex-1 items-center">
                        <svg
                          class="size-5 shrink-0 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium"
                            >coverletter_back_end_developer.pdf</span
                          >
                          <span class="shrink-0 text-gray-400">4.5mb</span>
                        </div>
                      </div>
                      <div class="ml-4 shrink-0">
                        <a
                          href="#"
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                          >Download</a
                        >
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
